<template>
  <div>
    <v-row class="match-height">
      <v-col cols="12">
        <v-card>
          <v-card-title>Post Edit</v-card-title>
          <v-card-text>
            <v-form
              ref="form"
              lazy-validation
              class="multi-col-validation"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="item.title"
                    :rules="[rules.required]"
                    label="Name"
                    outlined
                    dense
                    placeholder="Name"
                    hide-details
                  ></v-text-field>
                </v-col>

                <v-col
                  class="d-flex"
                  cols="12"
                  sm="6"
                >
                  <v-autocomplete
                    v-model="item.categories_id"
                    :rules="[rules.required]"
                    :items="cats"
                    item-text="name"
                    item-value="id"
                    label="Category"
                    chips
                    clearable
                    small-chips
                  ></v-autocomplete>
                </v-col>

                <v-col
                  class="d-flex"
                  cols="12"
                  sm="6"
                >
                  <v-textarea
                    v-model="item.description"
                    name="input-7-1"
                    label="Description"
                    value=""
                    outlined
                    dense
                  ></v-textarea>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="item.hastag1"
                    label="Hastag 1"
                    outlined
                    dense
                    placeholder="Hastag 1"
                    hide-details
                  ></v-text-field>
                  <v-text-field
                    v-model="item.hastag2"
                    class="mt-4"
                    label="Hastag 2"
                    outlined
                    dense
                    placeholder="Hastag 2"
                    hide-details
                  ></v-text-field>
                  <v-text-field
                    v-model="item.hastag3"
                    class="mt-4"
                    label="Hastag 3"
                    outlined
                    dense
                    placeholder="Hastag 3"
                    hide-details
                  ></v-text-field>
                </v-col>

                <v-col
                  class="d-flex"
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="item.related_to"
                    item-text="text"
                    item-value="value"
                    :items="relatedTos"
                    label="Related To"
                    dense
                    outlined
                  ></v-select>
                </v-col>

                <v-col
                  v-if="item.related_to == 1"
                  cols="12"
                  md="6"
                >
                  <v-autocomplete
                    v-model="item.related_to_user"
                    item-text="name"
                    item-value="item_id"
                    :items="users"
                    label="Related To User"
                    chips
                    clearable
                    small-chips
                  ></v-autocomplete>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-file-input
                    v-model="item.new_image"
                    label="Image or Video"
                    outlined
                    dense
                    @change="setImg"
                  ></v-file-input>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                  class="justify-center d-flex"
                >
                  <img
                    v-if="item.image && fileType == 'image'"
                    :src="item.image"
                    class="preview-img"
                  />
                  <video
                    v-show="fileType == 'video'"
                    id="video-preview"
                    class="max-w-full"
                    :class="{ 'opacity-0': fileType != 'video' }"
                    controls
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-checkbox
                    v-model="item.custom_name"
                    class="mt-0"
                    value="1"
                    false-value="0"
                  >
                    <template v-slot:label>
                      <div>Need to add Custom Name ?</div>
                    </template>
                  </v-checkbox>
                  <v-checkbox
                    v-model="item.custom_image"
                    class="mt-0"
                    value="1"
                    false-value="0"
                  >
                    <template v-slot:label>
                      <div>Need to add Custom Image ?</div>
                    </template>
                  </v-checkbox>
                </v-col>

                <v-col
                  class="d-flex"
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="item.status"
                    item-text="text"
                    item-value="value"
                    :items="statuses"
                    label="Status"
                    dense
                    outlined
                  ></v-select>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="item.comment"
                    label="Comment"
                    outlined
                    dense
                    placeholder="Comment"
                    hide-details
                  ></v-text-field>
                </v-col>

                <v-col
                  class="d-flex"
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="item.color"
                    item-text="text"
                    item-value="value"
                    :items="langs"
                    label="Language"
                    dense
                    outlined
                  ></v-select>
                </v-col>

                <v-col cols="12">
                  <v-btn
                    :loading="process"
                    color="primary"
                    @click="submit"
                  >
                    Submit
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiMagnify, mdiPencilOutline } from '@mdi/js'
import { serialize } from 'object-to-formdata'

export default {
  name: 'PostEdit',
  setup() {
    return {
      icons: {
        mdiMagnify,
        mdiPencilOutline,
      },
    }
  },
  data() {
    return {
      item: {},
      fileType: null,
      users: [],
      cats: [],
      statuses: [
        {
          text: 'Inactive',
          value: 0,
        },
        {
          text: 'Active',
          value: '1',
        },
        {
          text: 'Deleted',
          value: '2',
        },
        {
          text: 'Pending',
          value: '3',
        },
        {
          text: 'Rejected',
          value: '4',
        },
      ],
      relatedTos: [
        {
          text: 'All Users',
          value: '0',
        },
        {
          text: 'Specific Users',
          value: '1',
        },
      ],
      langs: [
        {
          text: 'ગુજરાતી',
          value: '5',
        },
        {
          text: 'हिंदी',
          value: '6',
        },
        {
          text: 'English',
          value: '7',
        },
      ],
      rules: {
        required: value => !!value || 'Required.',
      },
      process: false,
    }
  },
  computed: {
    init() {
      return this.$store.getters['Init/init']
    },
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      const url = `posts/${this.$route.params.id}`
      this.$api.get(url).then(response => {
        this.item = response.data.data
        this.users = response.data.users
        this.cats = response.data.post_categories

        if (this.item.image != null) {
          this.item.image = process.env.VUE_APP_MEDIA_URL + this.item.image
          if (this.item.file_type == 'image') {
            this.fileType = 'image'
          } else {
            this.fileType = 'video'
            const video = document.getElementById('video-preview')
            video.src = this.item.image
          }
        }
      })
    },
    setImg() {
      if (this.item.new_image == null) {
        this.item.image = null
      } else if (this.item.new_image.type.includes('video')) {
        this.fileType = 'video'
        const video = document.getElementById('video-preview')
        const reader = new FileReader()

        reader.readAsDataURL(this.item.new_image)
        reader.addEventListener('load', () => {
          video.src = reader.result
        })
      } else if (this.item.new_image.type.includes('image')) {
        this.fileType = 'image'
        this.item.image = URL.createObjectURL(this.item.new_image)
      } else {
        this.$toast.error('Please upload image or video only.')
        this.item.image = null
        this.fileType = null
        this.item.new_image = null
      }
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.process = true
        this.item._method = 'PUT'
        const options = {
          indices: false,
        }
        const formData = serialize(this.item, options)
        this.$api
          .post(`posts/${this.item.id}`, formData, {
            'Content-Type': 'multipart/form-data',
          })
          .then(response => {
            this.$toast.info(response.message)
            this.$router.push({ name: 'PostList' })
            this.process = false
          })
          .catch(() => {
            this.process = false
          })
      }
    },
  },
}
</script>
